.top {
  width: 100%;
  @include position(fixed,1003,0,null,null,null);
  white-space: nowrap;
  text-align: center;
  @include transition(all,0.6s,ease,0s);

  .top_info {
    background: #253135;
    padding: 10px 0;
    position:relative;
    z-index:1003;
    .content {
      text-align: right;

      &>div {
        display: inline-block;
        margin-right: 13px;
        vertical-align: middle;

        p {
          @include font(Inter,14px,#fff,1);
          font-weight: 600;

          span {
            color: #9C9C9C;
          }
        }
      }
      .lang {
        position:relative;
        background:#3C494E;
        margin:0;
        border-radius:6px 6px 0px 0px;
        vertical-align: middle;
        .drop-dl{
          a {
            .lng {
              padding:5px 0 5px 10px;
            }
            .drop {
              padding: 8px 10px;
              vertical-align: top;
            }
          }
          ul {
           display: none;
           position:absolute;
           left:0;
           top:21px;
           z-index:10;
           background:#3C494E;
           padding:10px 10px 0 10px;
           border-radius:0px 6px 6px 6px;
           @media(max-width:1024px){
             left:inherit;
             right:0;
             border-radius:6px 0px 6px 6px;
           }
           li {
             list-style-type: none;
             text-align:left;
             margin-bottom:10px;
             img{
               margin-right:8px;
             }
             span {
               @include font(Inter,10px,#f6f6f6,1);
             }
           }
         }
        }
      }
      @media(max-width:500px){
        .top_email{
          display:none;
        }
      }
    }
  }

  .top_menu {
    height: 70px;
    background: #303B3F;

    .content {
      height: 70px;

      .logo {
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1002;
        @media(max-width:1440px) {
          left: 4%;
        }
      }

      #menu {
        position: absolute;
        right: 100px;
        top: 50%;
        transform: translateY(-50%);
        @media(max-width:1440px) {
          right: 4%;
        }

        ul {
          li {
            margin-left: 40px;
            display: inline-block;

            a {
              @include font(Inter,18px,#fff,1);
              font-weight: 700px;
            }
          }
        }
      }
    }
  }
}
