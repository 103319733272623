.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(31,31,31,0.8);
  z-index: 10000;
  display: none;

  .box {
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 90vh;
    max-width: 800px;
    @media(max-width:880px) {
      width: 90%;
      max-height: 90vh;
      overflow-y: auto;
    }
    width: 100%;
    transform: translate(-50%,-50%);

    .form {
      box-sizing: border-box;
      padding: 30px 50px;
      @media(max-width:880px) {
        padding: 40px 8% 20px;
      }
      background: #F6F6F6;
      border-radius: 10px;
      .pop-close {
        position:absolute;
        right:10px;
        top:10px;
        cursor:pointer;
      }
      .txt {
        @include font(Inter,24px,#3a3a3a,1.4);
        font-weight: 600;
        text-align: left;
      }

      .wpcf7 {
        margin-top: 50px;

        .wpcf7-form-control-wrap {
          width: 48.5%;
          display: inline-block;
          @media(max-width:700px) {
            width: 100%;
          }
        }

        .email,
        .firma {
          margin-right: 1.5%;
        }

        .imie,
        .phone {
          margin-left: 1.5%;
        }

        input {
          background: rgba(255,255,255,1);
          box-sizing: border-box;
          width: 100%;
          border: none;
          margin-bottom: 25px;
          padding: 20px 30px;
          border-radius: 5px;
          @include font(Inter,18px,rgba(58,58,58,0.6),1);
          display: inline-block;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.06);

          &::placeholder {
            color: rgba(58,58,58,0.6);
          }
        }

        .accept,
        .accept2 {
          text-align: left;
          padding-left: 5px;
          box-sizing: border-box;
          @media(max-width:700px) {
            margin-bottom: 15px;
          }

          label {
            cursor: pointer;
            @include font(Inter,14px,#3a3a3a,1.4);
          }

          input {
            box-shadow: none;
          }
        }

        input[type="checkbox"] {
          margin-top: 50px;
          float: left;
          width: inherit;
          cursor: pointer;
          margin: 7px 10px 10px 0;
          transform: scale(1.5);
        }

        .center {
          text-align: right;
          display: inline-block;
          width: 100%;
          @media(max-width:700px) {
            text-align: center;
          }

          input[type="submit"] {
            margin-top: 30px;
            background: #4DC1C1;
            @include font(Inter,24px,#fff,1);
            font-weight: 700;
            margin-bottom: 0;
            cursor: pointer;
            width: auto;
            @media(max-width:700px) {
              font-size: 18px;
            }
          }
        }

        .wpcf7-spinner {
          display: none;
        }

        .wpcf7-response-output {
          @include font(Inter,14px,#3a3a3a,1.4);
          border-color: #4DC1C1;
          background: #FBFBFB;
          margin: 10px 0 0;
          width: 100%;
          box-sizing: border-box;
        }
        @media(max-width:880px) {
          .email,
          .firma {
            margin: 0;
            width: 100%;
          }

          .imie,
          .phone {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

#footer {
  background: #3A3A3A;


  .content {
    .bglines {
      padding: 100px 0 50px;
      @media(max-width:1200px) {
        padding: 50px 0 20px;
      }
      @media(max-width:800px) {
        padding: 20px 0;
      }
    }
    & > div > div {
      vertical-align: top;
      @include rwd-box(calc(100%/12*2),0,0,0,0);

      h6 {
        @include font(Inter,24px,#4dc1c1,1);
        font-weight: 700;
        margin-bottom: 30px;
      }

      .txt {
        h6 {
          @include font(Inter,18px,#fff,2);
          font-weight: 600;
          margin-bottom: 0;
        }

        a {
          text-decoration: underline;
          color: #fff;
        }

        p {
          @include font(Inter,16px,#fff,2);
          font-weight: 600;
        }
      }
    }

    .box-2,
    .box-4 {
      @include rwd-box(calc(100%/12*3),0,0,0,0);
    }

    .box-4,
    .box-5 {
      margin-top: 55px;

      a,
      p {
        @include font(Inter,14px,#fff,2);
        font-weight: 600;
        display: block;
        text-decoration: none!important;
      }

      span {
        color: #9C9C9C;
      }

      img {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    @media(max-width:1200px) {
      .box-1 {
        width: calc(100%/12*4);
      }

      .box-2 {
        width: calc(100%/12*5);
      }

      .box-3 {
        width: calc(100%/12*3);
      }

      .box-4 {
        width: calc(100%/12*5);
        padding-left: calc(100%/12*1);
      }

      .box-5 {
        width: calc(100%/12*7);
        padding-left: calc(100%/12*3);
        text-align: left;
      }
    }
    @media(max-width:800px) {
      .box-1,
      .box-3,
      .box-4,
      .box-5 {
        width: 100%;
        padding: 15px 0;
        text-align: center;
        margin: 0;
      }

      .box-2 {
        display: none;
      }
    }
    @media(max-width:520px) {
      div {
        text-align: left!important;
      }
    }
  }
}

.footer_bottom {
  background: #2F2F2F;

  .content {
    height: 70px;
    @media(max-width:520px) {
      height: inherit;
      text-align: center;
    }
    .bglines {
      height:70px;
      @media(max-width:520px) {
        height: inherit;
        text-align: center;
      }
    }

    .privacy {
      position: absolute;
      left: 100px;
      top: 50%;
      transform: translateY(-50%);

      a {
        @include font(Inter,12px,#fff,1);
        display: block;
        padding: 5px 0;
      }
      @media(max-width:1440px) {
        left: 4%;
      }
      @media(max-width:520px) {
        position: relative;
        transform: translateY(0);
        left: 0;
        margin: 10px 0;
      }
    }

    .copyright {
      position: absolute;
      right: 100px;
      top: 50%;
      transform: translateY(-50%);

      span {
        @include font(Inter,12px,#fff,1);
      }
      @media(max-width:1440px) {
        right: 4%;
      }
      @media(max-width:520px) {
        position: relative;
        transform: translateY(0);
        right: 0;
        margin: 10px 0;
      }
    }
  }
}

#cookies-message {
  display: none;
  padding: 10px 15px;
  box-sizing: border-box;
  border-top: 1px solid #15151f;
  text-align: center;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  z-index: 999;

  p {
    @include font(Inter,13px,#000,1);
    padding-right: 150px;

    a {
      color: #4dc1c1;
    }
  }

  #accept-cookies-checkbox {
    display: inline-block;
    background: #2F2F2F;
    border-radius: 5px;
    padding: 17px 19px;
    @include font(Inter,14px,#fff,1);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    @include transform(translateY(-50%));
  }
}
