.lang {
  position:fixed;
  top:15px;
  left:15px;
  z-index:1000;
  background:#3C494E;
  margin:0;
  border-radius:6px 6px 0px 0px;
  vertical-align: middle;
  .drop-dl{
    a {
      .lng {
        padding:5px 0 5px 10px;
      }
      .drop {
        padding: 8px 10px;
        vertical-align: top;
      }
    }
    ul {
     display: none;
     position:absolute;
     left:0;
     top:21px;
     z-index:10;
     background:#3C494E;
     padding:10px 10px 0 10px;
     border-radius:0px 6px 6px 6px;
     width:70px;

     li {
       list-style-type: none;
       text-align:left;
       margin-bottom:10px;
       img{
         margin-right:8px;
       }
       span {
         @include font(montserratlight,10px,#fff,1);
       }
       a {
         text-decoration:none;
       }
     }
   }
  }
}

body {
    min-height:100vh;
    .swipe {
        display:none;
        @media(max-width:800px){
            display:block;
            position:fixed;
            right:5%;
            bottom:5%;
            z-index:100;
        }
    }
    .slider {
        .slid{
            width:100vw;
            height:100vh;
            position:relative;
            min-height:100vh;
            @media(max-width:800px){
                height:inherit;
            }
            .bgimg{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                right: 0;
                bottom:0;
                left:40%;
                top:0;
                position:absolute;
                @media(max-width:1300px){
                    left:64%;
                }
                @media(max-width:800px){
                    left:0;
                    height:100%;
                    min-height:100vh;
                }
                .plus {
                    position:absolute;
                    right: 16%;
                    bottom: 5%;
                    @media(max-width:800px){
                        display:none;
                    }
                }
                .kropki_szare{
                    position:absolute;
                    right:2%;
                    bottom:17%;
                    @media(max-width:800px){
                        display:none;
                    }
                }


            }
            .left {
                background:rgba(31,31,31,1);
                border-top-right-radius: 55px;
                border-bottom-right-radius: 55px;
                position:absolute;
                left:0;
                top:0;
                bottom:0;
                right:54%;
                @media(max-width:1300px){
                    right:30%;
                }
                @media(max-width:800px){
                    right:0;
                    position:relative;
                    width:80%;
                    padding:60px 0;
                    background:rgba(31,31,31,.8);
                    @media(max-width:400px){
                        padding:15px 0 20px 0;
                    }
                }
                .box {
                    text-align:center;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0 20%;
                    width: 100%;
                    box-sizing: border-box;
					@media(max-width:1630px){
					padding:0 15%;
					}
                    @media(max-width:1500px){
                        padding:0 5%;
                    }
                    @media(max-width:800px){
                        right:0;
                        position:relative;
                        transform: translateY(0);
                    }
                }
                .logo {
                    padding-bottom:42px;
                    @media(max-width:1600px){
                        padding-bottom:15px;
                    }
                    img {
                        display:inline-block;
                        @media(max-width:800px){
                            width:190px;
                        }
                        @media(max-width:400px){
                            width:170px;
                        }
                    }
                }
                .header {
                    h1,h2 {
                        font-family:montserratextralight;
                        font-size:21px;
                        color:#fff;
                        line-height: 1.2;
                        position:relative;
                        letter-spacing: 3px;
                        @media(max-width:400px){
                            font-size:18px;
                        }
                        &:before {
                            content:"";
                            width:135px;
                            height:1px;
                            background:#707070;
                            position:absolute;
                            left:50%;
                            transform:translateX(-50%);
                            bottom:-10px;
                        }
                    }
                }
                .content {
                    padding-top:60px;
                    text-align:left;
                    @media(max-width:1600px){
                        padding-top:25px;
                    }
                    p {
                        font-family:montserratlight;
                        font-size:15px;
                        color:#fff;
                        line-height:1.4;
                        @media(max-width:1600px){
                            font-size:14px;
                            line-height: 1.3;
                        }
                    }
                    ul {
                        list-style-position: inside;
                        list-style-type: disc;
                        li {
                            font-family:montserratlight;
                            font-size:15px;
                            color:#fff;
                            line-height:1.4;
                            @media(max-width:1600px){
                                font-size:14px;
                                line-height: 1.3;
                            }
                        }
                    }
                }
                .__button {
                    padding-top:50px;
                    @media(max-width:1600px){
                        padding-top:25px;
                    }
                    @media(max-width:400px){
                        padding-top:5px;
                    }
                    a {
                        text-decoration:none;
                        .img {
                            position:relative;

                            &:before {
                                content:"";
                                width:135px;
                                height:1px;
                                background:#707070;
                                position:absolute;
                                left:50%;
                                transform:translateX(-50%);
                                bottom:-20px;
                                @media(max-width:400px){
                                    bottom:-5px;
                                }
                            }
                            img {
                                display:inline-block;
                            }
                        }
                        p {
                            padding-top:35px;
                            font-family:montserratbold;
                            font-size:18px;
                            color:#fff;
                            letter-spacing: 3px;
                            @media(max-width:400px){
                                padding-top:15px;
                            }
                        }
                    }
                }
				.button {
                    padding-top:50px;
					text-align:center;
                    @media(max-width:1600px){
                        padding-top:25px;
                    }
                    @media(max-width:400px){
                        padding-top:5px;
                    }
					.but2 {
					display:inline-block;
					background:#FF914D;
					border-radius:10px;
					padding:20px 10px;
                    a {
                        text-decoration:none;
                        p {
                            font-family:montserratbold;
                            font-size:20px;
                            color:#000;
                            letter-spacing: 0px;
                            @media(max-width:400px){
                                padding-top:15px;
                            }
							text-transform:uppercase;
                        }
                    }
					}
                }
                .custom {
                    padding-top:35px;
                    @media(max-width:1600px){
                        padding-top:15px;
                    }
                    .custom-pagin {
                        display:inline-block;
                        vertical-align:middle;
                        ul {
                            display:inline-block;
                            li {
                                display:inline-block;
                                button {
                                    display:inline-block;
                                    border:none;
                                    background:none;
                                    width:65px;
                                    height:65px;
                                    background:#fff;
                                    border-radius:50%;
                                    font-size:0;
                                    margin:0 14px;
                                    cursor:pointer;
                                }
                            }
                            // li:nth-child(1){
                            //     button {
                            //         background:#555555;
                            //     }
                            // }
                            // li:nth-child(2){
                            //     button {
                            //         background:#B48831;
                            //     }
                            // }
                            // li:nth-child(3){
                            //     button {
                            //         background:#E20064;
                            //     }
                            // }
                            // li:nth-child(4){
                            //     button {
                            //         background:#4747A0;
                            //     }
                            // }



                        }
                    }
                    .carousel-nav {
                        background:none;
                        border:none;
                        vertical-align:middle;
                        cursor:pointer;
						@media(max-width:700px){
						display:none;
						}
                        img {

                        }
                    }
                }
            }
        }
        .slid1 {
            .left {
			.button {
			.but2 {
			background:none!important;
			a {
			p {
			color:#fff!important;
			}
			}
				}
				}
			
                .custom {
                    .custom-pagin {
                        ul{
                            li:nth-child(1){
                                button {
                                    width:80px;
                                    height:80px;
                                }
                            }
                        }

                    }
                }
            }
        }
        .slid2{
            .left {
                background:#D6D6D6;
                @media(max-width:800px){
                    background:rgba(214,214,214,.8);
                }
                .header {
                    h1,h2 {
                        color:#434342;
                        &:before {
                            background:#B48831;
                        }
                    }
                }
                .content {
                    p {
                        color:#151515;
                    }
                    ul {
                        li {
                            color:#151515;
                        }
                    }
                }
                .button {
                    a {
                        .img {
                            &:before {
                                background:#B48831;
                            }
                        }
                        p {
                            color:#434342;
                        }
                    }
                }
                .custom {
                    .custom-pagin {
                        ul{
                            li:nth-child(2){
                                button {
                                    width:80px;
                                    height:80px;
                                }
                            }
                        }

                    }
                }
            }
        }
        .slid3{
            .left {
                background:#D6D6D6;
                @media(max-width:800px){
                    background:rgba(214,214,214,.8);
                }
                .header {
                    h1,h2 {
                        color:#434342;
                        &:before {
                            background:#E20064;
                        }
                    }
                }
                .content {
                    p {
                        color:#151515;
                    }
                    ul {
                        li {
                            color:#151515;
                        }
                    }
                }
                .button {
                    a {
                        .img {
                            &:before {
                                background:#E20064;
                            }
                        }
                        p {
                            color:#434342;
                        }
                    }
                }
                .custom {
                    .custom-pagin {
                        ul{
                            li:nth-child(3){
                                button {
                                    width:80px;
                                    height:80px;
                                }
                            }
                        }

                    }
                }
            }
        }
        .slid4{
            .left {
                background:#0F0F1D;
                @media(max-width:800px){
                    background:rgba(214,214,214,.8);
                }
                .header {
                    h1,h2 {
                        color:#fff;
                        &:before {
                            background:#B7902D;
                        }
                    }
                }
                .content {
                    p {
                        color:#fff;
                    }
                    ul {
                        li {
                            color:#fff;
                        }
                    }
                }
                .button {
                    a {
                        .img {
                            &:before {
                                background:#fff;
                            }
                        }
                        p {
                            color:#fff;
                        }
                    }
                }
                .custom {
                    .custom-pagin {
                        ul{
                            li:nth-child(4){
                                button {
                                    width:80px;
                                    height:80px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
    #splide{
        width:100vw;
        height:100vh;
        overflow: hidden;
        .splide__track{
            .splide__list{

            }
        }
    }
}
