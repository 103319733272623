//BURGER

.burger-menu {
  @include position(absolute,1002,50%,4%,null,null);
  @include transition(all,0.7s,ease,0s);
  background-color: transparent;
  transform: translateY(-50%);

  .burger-click-region {
    position: relative;
    width: 28px;
    height: 25px;
    cursor: pointer;
    text-align: right;

    .burger-menu-piece {
      display: block;
      position: absolute;
      width: 28px;
      height: 5px;
      background: #4DC1C1;
      border-radius: 15px;
      @include transform-origin(50%,50%);
      @include transition(transform,400ms,ease-out,0s);
      @include transition(background,400ms,ease-out,0s);
      left: 0;
    }

    .burger-menu-piece:nth-child(1) {
      top: 0;
      left: 0;
    }

    .burger-menu-piece:nth-child(2) {
      top: 10px;
      opacity: 1;
      left: inherit;
    }

    .burger-menu-piece:nth-child(3) {
      top: 20px;
      left: 0;
    }
  }
}

.burger-menu.actives {
  .burger-menu-piece:nth-child(1) {
    @include transform(translate3d(0,11px,0),rotate(45deg));
  }

  .burger-menu-piece:nth-child(2) {
    opacity: 0;
  }

  .burger-menu-piece:nth-child(3) {
    @include transform(translate3d(0,-9px,0),rotate(-45deg));
  }
}
//BURGER
//WŁĄCZ WYŁĄCZ BURGER KIEDY BĘDZIE OKREŚLONA SZEROKOŚĆ
@media (max-width: 800px) {
  #menu {
    display: none!important;
  }
}
@media all and (min-width: 801px) {
  .burger-menu {
    display: none;
  }
}
// MOBILNE MENU \/

.mobile-menu {
  height: inherit;
  @include position(fixed,0,-100%,0px,null,null);
  width: 100%;
  background: #303B3F;
  @include transition(all,0.6s,ease,0s);
  height: 100vh;
  box-sizing: border-box;
  padding-top: 120px;

  ul {
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    padding-left: 4%;

    li {
      padding: 20px 0;
      text-transform: uppercase;

      a {
        @include font(Inter,32px,#fff,1);
        font-weight: 700;
        @media(max-width:500px) {
          font-size: 22px;
        }
      }
    }
  }
}

.active {
  top: 0!important;
}
// MOBILNE MENU //
