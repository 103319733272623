@mixin svg-color($color){
    path,rect,circle {
        stroke:$color!important;
        @include transition(all,0.7s,ease,0s);
    }
}
@mixin position($position,$z-index:null,$top:null,$right:null,$bottom:null,$left:null){
    position:$position;
    @if $z-index != null{
        z-index:$z-index;
    }
    @if $top != null{
        top:$top;
    }
    @if $right != null{
        right:$right;
    }
    @if $bottom != null{
        bottom:$bottom;
    }
    @if $left != null{
        left:$left;
    }
}
@mixin placeholder-bg($col){
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        background-color:$col;
    }
    &::-moz-placeholder { /* Firefox 19+ */
     background-color:$col;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      background-color:$col;
    }
    &:-moz-placeholder { /* Firefox 18- */
        background-color:$col;
    }
}
@mixin placeholder($opacity,$color,$align){
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        opacity:$opacity;
        color:$color;
        @if $align != null{
            text-align:$align;
        }
    }
    &::-moz-placeholder { /* Firefox 19+ */
        opacity:$opacity;
        color:$color;
        @if $align != null{
            text-align:$align;
        }
    }
    &:-ms-input-placeholder { /* IE 10+ */
        opacity:$opacity;
        color:$color;
        @if $align != null{
            text-align:$align;
        }
    }
    &:-moz-placeholder { /* Firefox 18- */
        opacity:$opacity;
        color:$color;
        @if $align != null{
            text-align:$align;
        }
    }
}
@mixin rwd-box($width,$a:null,$b:null,$c:null,$d:null){
    width:$width;
    display:inline-block;
    box-sizing:border-box;
    @if $a != null and $b != null and $c != null and $d != null{
        padding:$a $b $c $d;
    }
}
@mixin font($family,$size,$color,$line-height,$family2:null){
    font-family:$family,$family2;
    font-size:$size;
    color:$color;
    line-height:$line-height;
}
@mixin ghost-element{
    content:"";
    display:inline-block;
    vertical-align:middle;
    margin-left:-0.25em;
}
@mixin ghost-content {
    display:inline-block;
    vertical-align:middle;
}
@mixin mask($r,$g,$b,$a){
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba($r,$g,$b,$a);
}
@mixin background-image {
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
}
@mixin last-justify($justify) {
    // justify
    -webkit-text-align-last: $justify;
    -moz-text-align-last: $justify;
    -ms-text-align-last: $justify;
    -o-text-align-last: $justify;
    text-align-last: $justify;
}
@mixin animation-duration($time) {
    // ANIMATION DURATION
    -webkit-animation-duration: $time;
    -moz-animation-duration: $time;
    -ms-animation-duration: $time;
    -o-animation-duration: $time;
    animation-duration: $time;
}

@mixin transform-origin($x:null, $y:null, $z:null) {
    // Transform origin
    -webkit-transform-origin: $x $y $z;
    -moz-transform-origin: $x $y $z;
    -ms-transform-origin: $x $y $z;
    -o-transform-origin: $x $y $z;
    transform-origin: $x $y $z;
}

@mixin h-center
{ // centrowanie poziome margin auto dla elementów normalnej treści
  max-width: $page-width;
  margin: 0 auto;
}

// graphics filters: grayscale(), saturate(), hue-rotate(deg),
//                   contrast(), brightness(),
//                   blur(), invert(), sepia(), opacity()
// (max. 4 filters) [can be xpnded later]
@mixin filter($f1:null, $f2:null, $f3:null, $f4:null) {
    filter: $f1 $f2 $f3 $f4;
    -webkit-filter: $f1 $f2 $f3 $f4;
}

@mixin v-center
{ // centrowanie elementu w pionie dla elementów normalnej treści
  display: inline-block;
  vertical-align: middle;
}

@mixin h-center-m
{ // centrowanie poziome dla elementów float (i innych poza normalną treścią)
  left: 50%;
  margin-left: -($page-width/2);
}


@mixin font-size-adjust($font, $percent) {
    @if length($font) == 2 {
        font-size: nth($font, 1) * $percent;
    }
}

@mixin bcg-opacity($color: white, $opacity: 0.5) {
    background: $color; // Old browsers
    background: -moz-linear-gradient(top, rgba($color,$opacity) 0%, rgba($color,$opacity) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba($color,$opacity)), color-stop(100%,rgba($color,$opacity))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($color,$opacity) 0%,rgba($color,$opacity) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($color,$opacity) 0%,rgba($color,$opacity) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($color,$opacity) 0%,rgba($color,$opacity) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($color,$opacity) 0%,rgba($color,$opacity) 100%); // W3C
}

@mixin bcg-linear($top: white, $bottom: black) {
    background: $top; // Old browsers
    background: -moz-linear-gradient(top, rgba($top,1) 0%, rgba($bottom,1) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba($top,1)), color-stop(100%,rgba($bottom,1))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($top,1) 0%,rgba($bottom,1) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($top,1) 0%,rgba($bottom,1) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($top,1) 0%,rgba($bottom,1) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($top,1) 0%,rgba($bottom,1) 100%); // W3C
}

@mixin bcg-linear-v($left: black, $right: white) {
    background: $left; // Old browsers
    background: -moz-linear-gradient(left, rgba($left,1) 0%, rgba($right,1) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba($left,1)), color-stop(100%,rgba($right,1))); // Chrome,Safari4+
    background: -webkit-linear-gradient(left, rgba($left,1) 0%,rgba($right,1) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(left, rgba($left,1) 0%,rgba($right,1) 100%); // Opera 11.10+
    background: -ms-linear-gradient(left, rgba($left,1) 0%,rgba($right,1) 100%); // IE10+
    background: linear-gradient(to right, rgba($left,1) 0%,rgba($right,1) 100%); // W3C
}

@mixin bcg-pipe($edge: white, $mid: black, $edgeop: 1, $midop: 1, $div: 50%) {
    background: $edge; // Old browsers
    background: -moz-linear-gradient(top, rgba($edge,$edgeop) 0%, rgba($mid,$midop) $div, rgba($edge,$edgeop) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($edge,$edgeop)), color-stop($div, rgba($mid,$midop)), color-stop(100%, rgba($edge,$edgeop))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($edge,$edgeop) 0%, rgba($mid,$midop) $div, rgba($edge,$edgeop) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($edge,$edgeop) 0%, rgba($mid,$midop) $div, rgba($edge,$edgeop) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($edge,$edgeop) 0%, rgba($mid,$midop) $div, rgba($edge,$edgeop) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($edge,$edgeop) 0%, rgba($mid,$midop) $div, rgba($edge,$edgeop) 100%); // W3C
}

@mixin bcg-gradient($start: #fff, $mid: #888, $end: #000, $startop: 0, $midop: 0.5, $endop: 1, $div: 50%) {
    background: $start; // Old browsers
    background: -moz-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($start,$startop)), color-stop($div, rgba($mid,$midop)), color-stop(100%,rgba($end,$endop))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // W3C
}

@mixin bcg-gradient-v($start: #fff, $mid: #888, $end: #000, $startop: 0, $midop: 0.5, $endop: 1, $div: 50%) {
    background: $start; // Old browsers
    background: -moz-linear-gradient(left, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba($start,$startop)), color-stop($div, rgba($mid,$midop)), color-stop(100%,rgba($end,$endop))); // Chrome,Safari4+
    background: -webkit-linear-gradient(left, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(left, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Opera 11.10+
    background: -ms-linear-gradient(left, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // IE10+
    background: linear-gradient(to right, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // W3C
}

@mixin bcg-image-gradient($image, $x: 0px, $y: 0px, $start: #fff, $mid: #888, $end: #000, $startop: 0, $midop: 0.5, $endop: 1, $div: 50%) {
    background: $start; // Old browsers
    background: -moz-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($start,$startop)), color-stop($div, rgba($mid,$midop)), color-stop(100%,rgba($end,$endop))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($start,$startop) 0%, rgba($mid,$midop) $div, rgba($end,$endop) 100%); // W3C
}

@mixin bcg-2gradient($start: #fff, $mid1: #888, $mid2: #444, $end: #000, $startop: 0, $mid1op: 0.5, $mid2op: 0.5, $endop: 1, $div1: 40%, $div2: 60%) {
    background: $start; // Old browsers
    background: -moz-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid1,$mid1op) $div1, rgba($mid2,$mid2op) $div2, rgba($end,$endop) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($start,$startop)), color-stop($div1, rgba($mid1,$mid1op)),  color-stop($div2, rgba($mid2,$mid2op)), color-stop(100%,rgba($end,$endop))); // Chrome,Safari4+
    background: -webkit-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid1,$mid1op) $div1, rgba($mid2,$mid2op) $div2, rgba($end,$endop) 100%); // Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid1,$mid1op) $div1, rgba($mid2,$mid2op) $div2, rgba($end,$endop) 100%); // Opera 11.10+
    background: -ms-linear-gradient(top, rgba($start,$startop) 0%, rgba($mid1,$mid1op) $div1, rgba($mid2,$mid2op) $div2, rgba($end,$endop) 100%); // IE10+
    background: linear-gradient(to bottom, rgba($start,$startop) 0%, rgba($mid1,$mid1op) $div1, rgba($mid2,$mid2op) $div2, rgba($end,$endop) 100%); // W3C
}

@mixin bcg-3color-v($start: #fff, $mid: #aaa, $end: #000, $div1: 33%, $div2: 66%) {
    background: $mid; // Old browsers
    background: -moz-linear-gradient(left, rgba($start,1) 0%, rgba($start,1) $div1,  rgba($mid,1) $div1, rgba($mid,1) $div2, rgba($mid,1) $div2, rgba($end,1) 100%); // FF3.6+
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba($start, 1)), color-stop($div1, rgba($start,1)), color-stop($div1, rgba($mid,1)), color-stop($div2, rgba($mid,1)), color-stop($div2, rgba($end,1)), color-stop(100%,rgba($end,1))); // Chrome,Safari4+, Opera 29+
    background: -webkit-linear-gradient(left, rgba($start,1) 0%, rgba($start,1) $div1, rgba($mid,1) $div1, rgba($mid,1) $div2, rgba($end,1) $div2, rgba($end,1) 100%); // Chrome10+,Safari5.1+, Opera 29+
    background: -o-linear-gradient(left, rgba($start,1) 0%, rgba($start,1) $div1, rgba($mid,1) $div1, rgba($mid,1) $div2, rgba($mid,1) $div2, rgba($end,1) 100%); // Opera 11.10+?, (Opera29!-)
    background: -ms-linear-gradient(left, rgba($start,1) 0%, rgba($start,1) $div1, rgba($mid,1) $div1, rgba($mid,1) $div2, rgba($end,1) $div2, rgba($end,1) 100%); // IE10+
    background: linear-gradient(to right, rgba($start,1) 0%, rgba($start,1) $div1, rgba($mid,1) $div1, rgba($mid,1) $div2, rgba($end,1) $div2, rgba($end,1) 100%); // W3C
}

//
// Border radius for round corneer, cross browsers version
@mixin border-radius ($r1: 5px, $r2: null, $r3: null, $r4: null) {
    -khtml-border-radius:  $r1 $r2 $r3 $r4;
    -webkit-border-radius: $r1 $r2 $r3 $r4;
    -moz-border-radius:    $r1 $r2 $r3 $r4;
    border-radius:         $r1 $r2 $r3 $r4;
    //-moz-background-clip:    padding;
    //-webkit-background-clip: padding-box;
    //background-clip:         padding-box;
}

//
// dzielenie wyrazów
// none/auto/manual(&shy;)
@mixin hyphen-mode($mode: auto) {
    text-align: justify;
    -webkit-hyphens: $mode;
    -moz-hyphens: $mode;
    -ms-hyphens: $mode;
    hyphens: $mode;
}

// params: css property , duration(s/ms), timing function(see below), delay
// timing function: ease/linear/ease-in/ease-out/ease-in-out/cubic-bezier(n,n,n,n)/initial/iherit
@mixin transition($p1, $p2: 1s, $p3: linear, $p4: 0s) {
    -webkit-transition: $p1 $p2 $p3 $p4;
    -moz-transition: $p1 $p2 $p3 $p4;
    -o-transition: $p1 $p2 $p3 $p4;
    transition: $p1 $p2 $p3 $p4;
}

// j.w. , 2 parametry css
// params: css property , duration(s/ms), timing function(see below), delay
// timing function: ease/linear/ease-in/ease-out/ease-in-out/cubic-bezier(n,n,n,n)/initial/iherit
@mixin transition2($p1, $p5, $p2: 1s, $p3: linear, $p4: 0s, $p6: 1s, $p7: linear, $p8: 0s) {
    -webkit-transition: $p1 $p2 $p3 $p4, $p5 $p6 $p7 $p8;
    -moz-transition: $p1 $p2 $p3 $p4, $p5 $p6 $p7 $p8;
    -o-transition: $p1 $p2 $p3 $p4, $p5 $p6 $p7 $p8;
    transition: $p1 $p2 $p3 $p4, $p5 $p6 $p7 $p8;
}

// params: transform functions like scale, rotate, translate, skew, perspective, matrix
@mixin transform($p1, $p2: null, $p3: null, $p4: null) {
    -webkit-transform: $p1 $p2 $p3 $p4;
    -moz-transform: $p1 $p2 $p3 $p4;
    -ms-transform: $p1 $p2 $p3 $p4;
    -o-transform: $p1 $p2 $p3 $p4;
    transform: $p1 $p2 $p3 $p4;
}
// params: transform-orgin axis x,y,z(length) (left/center/right/length/%)
@mixin transform-origin($x: 50%, $y: 50%, $z: 0) {
    -webkit-transform-origin: $x $y $z;
    -moz-transform-origin:  $x $y $z;
    -ms-transform-origin:  $x $y $z;
    -o-transform-origin:  $x $y $z;
    transform-origin:  $x $y $z;
}

@mixin box-shadow($hsh, $vsh, $blur: 0px, $spread: 0px, $color: rgba(50,50,50,0.75)) {
    -webkit-box-shadow: $hsh $vsh $blur $spread $color;
    -moz-box-shadow:    $hsh $vsh $blur $spread $color;
    box-shadow:         $hsh $vsh $blur $spread $color;
}

@mixin box-shadow-inner($hsh, $vsh, $blur: 0px, $spread: 0px, $color: rgba(50,50,50,0.75)) {
    -webkit-box-shadow: inset $hsh $vsh $blur $spread $color;
    -moz-box-shadow:    inset $hsh $vsh $blur $spread $color;
    box-shadow:         inset $hsh $vsh $blur $spread $color;
}

@mixin box-shadows($p1, $p2:null, $p3:null, $p4:null) {
    -webkit-box-shadow: $p1, $p2, $p3, $p4;
    -moz-box-shadow:    $p1, $p2, $p3, $p4;
    box-shadow:         $p1, $p2, $p3, $p4;
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//
@mixin fontface ($facename, $weight, $style, $filename, $fontpath: '../fonts') {
    @font-face {
        font-family: $facename;
        src: url('#{$fontpath}/#{$filename}.eot');
        src: url('#{$fontpath}/#{$filename}.eot?#iefix') format('embedded-opentype'),
            url('#{$fontpath}/#{$filename}.woff') format('woff'),
            url('#{$fontpath}/#{$filename}.ttf') format('truetype'),
            url('#{$fontpath}/#{$filename}.svg##{$filename}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}
