@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratextralight';
    src: url('../fonts/montserrat-extralight-webfont.eot');
    src: url('../fonts/montserrat-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extralight-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extralight-webfont.woff') format('woff'),
         url('../fonts/montserrat-extralight-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratlight';
    src: url('../fonts/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
